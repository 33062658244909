import { BlitzPage, Routes } from "@blitzjs/next"
import { useRouter } from "next/router"
import Layout from "app/core/layouts/Layout"
import { LoginForm } from "app/auth/components/LoginForm"
import { addQueryParams } from "app/core/utils/addQueryParams"
import { SafeUser } from "app/auth/utils/safeUserInclude"
import { parseAsBoolean, parseAsString, useQueryState } from "nuqs"

const LoginPage: BlitzPage = () => {
  const router = useRouter()
  const [next] = useQueryState("next", parseAsString)

  return (
    <div>
      <LoginForm
        onSuccess={(user) => {
          if (!user) {
            return router.push(Routes.LoginPage({ next }))
          }

          if (user.role === "ADMIN") {
            router.push(Routes.AdminLessonPage())
          } else if (
            user.organizationUsers &&
            user.organizationUsers[0].onboardingType === "ONBOARDING"
          ) {
            router.push(
              Routes.OnboardingGroup({
                next: router.query.next,
              })
            )
          } else if (
            user.organizationUsers &&
            user.organizationUsers[0].onboardingType === "SCHOOLYEAR"
          ) {
            router.push(
              Routes.ReboardingWelkomPage({
                next: router.query.next,
              })
            )
          } else {
            const next = router.query.next
              ? decodeURIComponent(router.query.next as string)
              : "/"
            router.push(next)
          }
        }}
      />
    </div>
  )
}

LoginPage.redirectAuthenticatedTo = "/"
LoginPage.getLayout = (page) => <Layout title="Log In">{page}</Layout>

export default LoginPage
